import * as React from "react"

const SvgComponent = (props) => (
  <svg
    width={props.width || 42}
    height={props.height || 42}
    fill="none"
    viewBox= "0 0 42 42"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
      <path
        d="M21 2.625c-10.147 0-18.375 8.441-18.375 18.843 0 8.326 5.266 15.38 12.567 17.874.103.022.207.033.312.033.68 0 .943-.5.943-.935 0-.451-.016-1.633-.024-3.208a8.4 8.4 0 0 1-1.854.222c-3.536 0-4.34-2.748-4.34-2.748-.836-2.174-2.042-2.756-2.042-2.756-1.6-1.124-.008-1.157.115-1.157h.008c1.845.164 2.813 1.952 2.813 1.952.92 1.608 2.15 2.06 3.249 2.06a5.168 5.168 0 0 0 2.1-.493c.164-1.214.64-2.042 1.165-2.518-4.077-.476-8.367-2.092-8.367-9.31 0-2.06.713-3.741 1.886-5.054-.188-.476-.82-2.395.18-4.987a1.53 1.53 0 0 1 .41-.041c.665 0 2.166.254 4.644 1.977a17.08 17.08 0 0 1 9.204 0c2.477-1.723 3.978-1.977 4.643-1.977a1.53 1.53 0 0 1 .41.04c1 2.593.369 4.512.18 4.988 1.173 1.32 1.887 3.002 1.887 5.053 0 7.235-4.298 8.827-8.392 9.294.657.583 1.247 1.731 1.247 3.487 0 2.518-.024 4.552-.024 5.168 0 .443.254.943.935.943.11 0 .22-.01.328-.033 7.309-2.494 12.567-9.556 12.567-17.874 0-10.402-8.228-18.843-18.375-18.843Z"
        fill="#CFCFCF"
      />
    </svg>
)

export default SvgComponent
